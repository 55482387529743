<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="4">
        <DatePicker placeholder="结算单开始结束日期" size="small" v-model="dateRage" type="daterange" split-panels
          style="width:100%" :editable="false" clearable @on-change="onChangeDate"></DatePicker>
      </i-col>
      <i-col span="4" >
        <Select v-model="settlementCompanyId"  @on-change="onChangeCompany" clearable filterable size="small" placeholder="结算主体">
          <Option v-for="(item,index) in companyArray" :key="'selCom_' + index" :value="item.settlementType+'_'+item.settlementCompanyId">{{ item.settlementCompanyName }}</Option>
        </Select>
      </i-col>

      <!-- <i-col span="3" >
        <Select v-model="query.companyId" clearable filterable size="small" placeholder="是否上传凭证">
          <Option v-for="item in companyArray" :key="'cid_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col> -->

      <i-col span="4" >
        <Select v-model="query.billStatus" clearable filterable size="small" placeholder="状态">
          <Option v-for="item in statusArray" :key="'status_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-input v-model="query.keyWord" size="small" placeholder="关键字（结算单编号）"></i-input>
      </i-col>
      <i-col span="4">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
      </i-col>
      <i-col span="4" style="text-align:right;">
        <!-- <a class="m-r-5">导出历史</a> -->
        <i-button size="small" class="m-l-5" type="success" icon="ios-search" @click="onExport">导出Excel</i-button>
      </i-col>
    </Row>

    <Table size="small" stripe :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { toMoney, toNumber } from '@/utils/wnumb_own'
import { formatbillsettlementStatus } from '@/utils/tagStatus'

import { listLifeBill, listLifeSettlementCompany, listLifeBillStatus, listLifeFeeItemType } from '@/api/statement/lifebill'
export default {
  props: {
    role: {
      type: Number, // 1:业主，2：地推公司
      default: 1
    }
  },
  data () {
    return {
      companyArray: [],
      salerArray: [],
      dateRage: [],
      settlementCompanyId: '',
      amount: 0, // 核销总金额
      quantity: 0, // 核销总数量
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: undefined,
        companyId: undefined,
        keyWord: '',
        userId: undefined,
        verifyStartDate: '',
        verifyEndDate: '',
        businessId: undefined
      },
      list: [],
      total: 0,

      tableLoading: false,
      tableColumns: [{ title: '结算单编号', key: 'code', align: 'center' },
        { title: '结算主体', key: 'settlementCompanyName', align: 'center' },
        {
          title: '结算周期',
          key: 'startDate',
          align: 'center',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.startDate),
              h(
                'span',
                ' 至 '
              ),
              h('span', data.row.endDate)
            ])
          }
        },
        {
          title: '结算单总金额(元)',
          key: 'amount',
          align: 'center',
          render (h, { row }) {
            return h('span', {
              style: { color: '#44bd32', 'font-weight': 'bold' }
            }, toMoney(row.amount))
          }
        },
        // { title: '上传凭证', key: 'buyerPhone', align: 'center' },
        {
          title: '状态',
          key: 'billStatus',
          align: 'center',
          render: (h, data) => {
            return formatbillsettlementStatus(
              h,
              data.row.billStatus,
              data.row.billStatusName
            )
          }
        },
        {
          title: '操作',
          key: 'operate',
          align: 'center',
          render: (h, params) => {
            const buttonArray = [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$store.commit('set_locallifesettlement_pageType', 'Detail')
                    this.$store.commit('set_locallifesettlement_billId', params.row.id)
                    // this.handleShowDetail(params.row.id)
                  }
                }
              }, '详情')
            ]
            return h('div', buttonArray)
          }
        }]
    }
  },
  created () {
    this.getCompanyData()
    this.getLifeFeeItemTypeData()
    this.getLifeIncomeTypeData()

    this.getTableData()
  },
  computed: {
    beginUpdate () {
      return this.$store.state.locallifesettlement.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.getTableData()
    }
  },
  methods: {

    getTableData () {
      this.tableLoading = true
      listLifeBill(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
    },

    onChangeCompany () {
      if (this.settlementCompanyId) {
        const keys = this.settlementCompanyId.split('_')
        this.query.settlementType = keys[0]
        this.query.settlementCompanyId = keys[1]
      } else {
        this.query.settlementType = null
        this.query.settlementCompanyId = null
      }
    },

    /**
     * 获取结算主体
     */
    getCompanyData () {
      this.companyArray = []
      listLifeSettlementCompany({ }).then(res => {
        if (res && !res.errcode) {
          this.companyArray = res
        }
      })
    },
    /**
     * 获取结算单状态
     */
    getLifeIncomeTypeData () {
      this.statusArray = []
      listLifeBillStatus({ }).then(res => {
        this.statusArray = res
      })
    },
    /**
     * 获取费用类型
     */
    getLifeFeeItemTypeData () {
      this.lifeFeeItemTypeArray = []
      listLifeFeeItemType({ }).then(res => {
        if (res && !res.errcode) {
          this.lifeFeeItemTypeArray = res
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    formartMoney (value) {
      return toMoney(value)
    },
    formartNumber (value) {
      return toNumber(value)
    },
    onExport () {
      const postData = {
        settlementType: this.query.settlementType,
        settlementCompanyId: this.query.settlementCompanyId,
        startDate: this.query.startDate,
        endDate: this.query.endDate,
        keyWord: this.query.keyWord
      }
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/lifeBill/exportLifeBills', postData, '生活圈结算账单.xlsx')
    }
  }
}
</script>
